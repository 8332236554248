.__landing-page {
  height: 100vh;
  background-color: var(--cream);

  .header {
    background-color: var(--green);
    font-family: var(--bungee);
    font-size: 60px;
    color: var(--cream);
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    text-align: center;
  }

  .content {
    background-color: var(--cream);
    display: flex;
    font-size: 48px;
    font-weight: 700;
    padding: 20px 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      min-width: 300px;
      max-width: 810px;
    }

    .totoro{
      margin-top: -80px;
    }

    svg {
      @media (max-width: 1000px) {
        width: 307px;
        height: 400px;
      }

      @media (max-width: 480px) {
        width: 161px;
        height: 210px;
      }
    }
  }

  @media (max-width: 480px) {
    .header {
      font-size: 22px;
      height: 100px;
    }
    .content {
      font-size: 17px;

      .text {
        min-width: 130px;
        max-width: 200px;
      }

      .totoro{
        margin-top: -30px;
      }
    }
  }
}