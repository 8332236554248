.__claim-gifts {
  background-color: var(--cream);

  .header {
    background-color: var(--green);
    font-family: var(--bungee);
    font-size: 60px;
    color: var(--cream);
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    text-align: center;
  }

  .content {
    background-color: var(--cream);
    width: 1176px;
    margin: 0 auto;
    padding: 40px 0;

    @media (max-width: 480px) {
      width: 100%;
      padding: 10px;
    }

    .summary {
      display: flex;
      @media (max-width: 480px) {
        flex-direction: column;
      }

      .snippet{
        font-size: 24px;
        line-height: 50px;
        width: 70%;

        @media (max-width: 480px) {
          width: 100%;
          line-height: 40px;
        }
      }

      .totoro {
        width: 30%;
        @media (max-width: 480px) {
          width: 100%;
        }

        svg {
          width: 960px;
          margin-right: -135px;
          float: right;

          @media (max-width: 480px) {
            width: 300px;
            margin-right: -10px;
          }
        }
      }
    }

    .gift-table {
      margin-top: -2px;

      .top-delimiter{
        height: 75px;
        width: 100%;
        background-color: var(--totoro);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
      }
      .bottom-delimiter {
        height: 75px;
        width: 100%;
        background-color: var(--totoro);
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        margin-top: 6px;
      }

      .gift-row {
        display: flex;
        margin-top: 6px;
        justify-content: space-between;
        font-weight: 600;

        .comment {
          color: var(--totoro);
          font-weight: normal;
        }

        &.gift-row-header {
          color: var(--cream);
          font-size: 18px;
          div {
            background-color: var(--green);
          }
        }

        &.swiped {
          div {
            color: var(--cream);
            background-color: var(--totoro);
          }
        }

        &.claimed {
          .status {
            width: 94px;
            min-height: 63px;
            border-top: 6px solid var(--green);
            border-left: 6px solid var(--green);
            border-bottom: 6px solid var(--green);
            @media (max-width: 480px) {
              width: 16%;
            }
          }
          .name {
            width: 1044px;
            min-height: 63px;
            border-top: 6px solid var(--green);
            border-bottom: 6px solid var(--green);
            border-right: 6px solid var(--green);
            @media (max-width: 480px) {
              width: 73%;
            }
          }
        }

        .status, .name {
          min-height: 75px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: var(--light-blue);
          box-sizing: content-box;
          text-align: center;
        }

        .status {
          width: 100px;
          @media (max-width: 480px) {
            width: 18%;
          }
        }
        .name {
          width: 1050px;
          padding: 0 10px;
          overflow-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word;
          @media (max-width: 480px) {
            width: 75%;
          }
        }

        .checkit {
          width: 30px;
          height: 30px;
          border: 5px solid var(--totoro);
          border-radius: 6px;
          cursor: pointer;
        }

        .uncheckit {
          width:  35px;
          height: 35px;
          cursor: pointer;

          @media (max-width: 480px) {
            width:  25px;
            height: 25px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .header {
      font-size: 22px;
      height: 100px;
    }
  }
}

.__success-modal .modal-content {
  background-color: var(--green);
  svg {
    height: 200px;
    width: 200px;
  }
}

.__failure-modal .modal-content {
  background-color: var(--red);

  svg {
    height: 300px;
    width: 575px;
  }
}

.__success-modal .modal-content, .__failure-modal .modal-content {
  border: 6px solid black;
  border-radius: 30px;
  color: black;
  padding: 0px 30px;
  text-align: center;

  .close-button {
    float: right;
    cursor: pointer;
    background-color: var(--cream);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: -25px;
    margin-right: -55px;
  }

  .content {
    display: flex;
    align-items: center;
    font-size: 30px;
    font-weight: bold;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
}